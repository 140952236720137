import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Container from '../Layout/SharedStyles/Container';
import IconSwirlMedium from '../Layout/Icons/IconSwirlMedium';
import Button from '../Button/Button';
import FormWrapper from './FormElements/FormWrapper/FormWrapper';
import FormRow from './FormElements/FormRow/FormRow';
import FormTextField from './FormElements/FormTextField/FormTextField';
import FormTextEmail from './FormElements/FormTextEmail/FormTextEmail';
import FormTextNumber from './FormElements/FormTextNumber/FormTextNumber';
import FormTextArea from './FormElements/FormTextArea/FormTextArea';
import FormSelect from './FormElements/FormSelect/FormSelect';
import style from './contact.mod.scss';

const schema = yup
  .object({
    // contactUs: yup.string().required('Contact Us must be required'),
    fullName: yup.string().required('Full Name must be required'),
    email: yup
      .string()
      .email('Email must be valid')
      .required('Email must be required'),
    phoneNumber: yup.string().required('Phone Number must be required'),
    // contactTime: yup.string().required('Contact Time must be required'),
    postcode: yup.string().required('Postcode must be required'),
    message: yup.string().optional(),
  })
  .required();

const ContactForm = ({ locale }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    handleFormSubmit(data);
  };

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  }

  const handleFormSubmit = (data) => {
    // event.preventDefault();
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact-form',
        subject: getSubject(),
        ...data,
      }),
    })
      .then((res) => (res.status === 200 ? setSuccess(true) : setError(true)))
      .catch((error) => alert(error));
  };

  const getSubject = () =>
    locale === 'en-AU'
      ? 'Contact Us Form from Sun & Soul AU'
      : 'Contact Us Form from Sun & Soul NZ';

  return (
    <>
      {success || error ? (
        <Container width="small" pt={2} pb={2}>
          {success && (
            <>
              <h4>Thank you</h4>
              <p>We will be in touch shorly</p>
            </>
          )}
          {error && (
            <>
              <h4>We are sorry</h4>
              <p>Something went wrong, please try again later</p>
            </>
          )}
        </Container>
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            name="contact-form"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input
              type="hidden"
              id="subject"
              name="subject"
              value={getSubject}
            />
            <input type="hidden" name="form-name" value="contact-form" />
            <Container width="small" pt={2} pb={2}>
              <div className={style.contact}>
                <div className={style.contact__header}>
                  <h2 className={style.contact__heading}>Get in touch</h2>
                  <IconSwirlMedium />
                </div>

                <div className={style.contact__body}>
                  <h4 className={style.contact__subHeading}>Contact Details</h4>
                  {/* <FormRow columns={1}>
                  <FormSelect
                    label="How would you like us to contact you?*"
                    placeholder="Select the preferred option"
                    list={['One', 'Two', 'Three']}
                    name="contactUs"
                  ></FormSelect>
                </FormRow> */}
                  <FormWrapper>
                    <FormRow columns={1}>
                      <FormTextField
                        label="Your Name*"
                        placeholder="Enter your full name"
                        name="fullName"
                      />
                    </FormRow>
                    <FormRow columns={1}>
                      <FormTextEmail
                        label="Your Email*"
                        placeholder="Enter your Email"
                        name="email"
                      />
                    </FormRow>
                    <FormRow columns={1}>
                      <FormTextNumber
                        label="Your Phone Number*"
                        placeholder="(##) ### ### or ### ### ####"
                        name="phoneNumber"
                      />
                      {/* <FormSelect
                      label="Best contact time*"
                      placeholder="Select the preferred contact time"
                      list={['One', 'Two', 'Three']}
                      name="contactTime"
                    ></FormSelect> */}
                    </FormRow>
                    <FormRow columns={1}>
                      <FormTextNumber
                        label="Your Postcode*"
                        placeholder="Enter your Postcode"
                        name="postcode"
                      />
                    </FormRow>
                    <FormRow columns={1}>
                      <FormTextArea
                        rows={3}
                        label="Message"
                        placeholder="Add your Message here"
                        name="message"
                      />
                    </FormRow>
                    <Button type="submit" fullWidth={true}>
                      Send Message
                    </Button>
                    {/* <button type="submit" name="submit" label="submit">
                    Submit
                  </button> */}
                  </FormWrapper>
                </div>
              </div>
            </Container>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default ContactForm;