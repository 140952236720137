import React from 'react';
import cn from 'classnames';
import { useFormContext, Controller } from 'react-hook-form';
import style from './formTextEmail.mod.scss';

const FormTextEmail = ({ label, name, placeholder, defaultValue }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = errors.hasOwnProperty(name) ? errors[name] : null;

  const classes = cn(style.formTextEmail__input, {
    [style[`formTextEmail__input-error`]]: !!error === true,
  });

  return (
    <div className={style.formTextEmail}>
      {label && (
        <label className={style.formTextEmail__label} htmlFor={label}>
          {label}
        </label>
      )}

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, name, ref } }) => (
          <div className={style.formTextEmail__content}>
            <input
              className={classes}
              id={label}
              inputRef={ref}
              type="email"
              name={name}
              defaultValue={defaultValue}
              placeholder={placeholder}
              onBlur={onBlur}
              onChange={onChange}
            />
            {!!error && (
              <span className={style.formTextEmail__error}>
                {error?.message}
              </span>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default FormTextEmail;
