import React from 'react';

const IconSwirlMedium = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 454.45 454.5"
      width={85}
      height={85}
    >
      <path
        id="Path_157"
        fill="#fff"
        d="M145.84 172.73c13.88 2.2 26.98 4.52 40.15 6.29 38.84 5.2 76.2-2.87 113.3-13.1 4.67-1.3 9.87-2.57 14.5-1.78 7.97 1.31 13.58 6.12 13.56 15.16 0 8.36-6.25 14.58-15.3 15.71-49.9 9.24-118.12 4.97-166.21-22.28Z"
        data-name="Path 157"
        transform="translate(113.73 127.86)"
      />
      <path
        id="Path_158"
        fill="#fff"
        d="M189.02 98.17c-13.88-2.2-26.98-4.52-40.15-6.28-38.84-5.24-76.2 2.88-113.3 13.1-4.67 1.3-9.87 2.56-14.5 1.78-7.97-1.32-13.58-6.13-13.56-15.17 0-8.37 6.25-14.58 15.3-15.72 49.9-9.23 118.12-4.96 166.2 22.29Z"
        data-name="Path 158"
        transform="translate(5.86 55.79)"
      />
      <path
        id="Path_159"
        fill="#fff"
        d="M98.17 145.84c-2.2 13.88-4.52 26.98-6.28 40.15-5.2 38.84 2.88 76.2 13.1 113.3 1.3 4.67 2.56 9.87 1.78 14.5-1.32 7.97-6.13 13.58-15.17 13.56-8.36 0-14.58-6.25-15.71-15.3-9.24-49.9-4.97-118.12 22.28-166.21Z"
        data-name="Path 159"
        transform="translate(55.73 113.78)"
      />
      <path
        id="Path_160"
        fill="#fff"
        d="M172.73 189.02c2.2-13.88 4.52-26.98 6.29-40.15 5.2-38.84-2.87-76.2-13.1-113.3-1.3-4.67-2.57-9.87-1.78-14.5 1.31-7.97 6.12-13.58 15.16-13.56 8.37 0 14.58 6.25 15.72 15.3 9.23 49.9 4.96 118.12-22.29 166.2Z"
        data-name="Path 160"
        transform="translate(127.81 5.9)"
      />
      <path
        id="Path_161"
        fill="#fff"
        d="M172.43 200.98c8.9-10.93 17.4-21.1 25.5-31.63 23.93-31.04 35.6-67.42 45.32-104.67 1.21-4.7 2.72-9.83 5.7-13.46 5.1-6.24 12.09-8.68 19.92-4.16 7.24 4.18 9.5 12.7 5.94 21.12-16.91 47.83-54.73 104.78-102.38 132.8Z"
        data-name="Path 161"
        transform="translate(134.47 34.94)"
      />
      <path
        id="Path_162"
        fill="#fff"
        d="M128.48 122.81c-8.9 10.93-17.4 21.1-25.5 31.63-23.93 31.04-35.6 67.42-45.32 104.67-1.21 4.7-2.72 9.83-5.7 13.46-5.1 6.24-12.09 8.68-19.92 4.16-7.24-4.18-9.48-12.7-5.94-21.13 16.91-47.82 54.73-104.78 102.38-132.79Z"
        data-name="Path 162"
        transform="translate(19.07 95.83)"
      />
      <path
        id="Path_163"
        fill="#fff"
        d="M122.81 172.43c10.93 8.9 21.1 17.4 31.63 25.5 31.04 23.93 67.42 35.6 104.67 45.32 4.7 1.21 9.83 2.72 13.46 5.7 6.24 5.1 8.68 12.09 4.16 19.92-4.18 7.24-12.7 9.5-21.12 5.94-47.83-16.91-104.79-54.73-132.8-102.38Z"
        data-name="Path 163"
        transform="translate(95.77 134.52)"
      />
      <path
        id="Path_164"
        fill="#fff"
        d="M200.99 128.48c-10.93-8.9-21.1-17.4-31.63-25.5-31.04-23.93-67.42-35.6-104.67-45.32-4.7-1.21-9.83-2.72-13.46-5.7C45 46.78 42.55 39.88 47 32.05c4.18-7.25 12.7-9.49 21.12-5.95C116.02 43.01 172.97 80.83 201 128.48Z"
        data-name="Path 164"
        transform="translate(34.88 19.12)"
      />
      <path
        id="Path_165"
        fill="#fff"
        d="M164 194.95c13.12-5.04 25.63-9.56 37.91-14.63 36.24-14.92 64.56-40.58 91.57-68 3.42-3.55 7.28-7.11 11.66-8.9 7.57-2.84 14.81-1.47 19.33 6.36 4.18 7.25 1.89 15.75-5.34 21.36-38.62 32.96-99.87 63.36-155.13 63.8Z"
        data-name="Path 165"
        transform="translate(127.9 79.64)"
      />
      <path
        id="Path_166"
        fill="#fff"
        d="M162.55 101.08c-13.12 5.04-25.63 9.56-37.9 14.63-36.25 14.92-64.56 40.58-91.58 67.99-3.42 3.47-7.28 7.12-11.66 8.81-7.56 2.85-14.8 1.48-19.33-6.35C-2.1 178.9.2 170.4 7.42 164.89c38.62-32.96 99.87-63.38 155.13-63.81Z"
        data-name="Path 166"
        transform="translate(0 78.88)"
      />
      <path
        id="Path_167"
        fill="#fff"
        d="M101.08 164c5.04 13.12 9.56 25.63 14.63 37.91 14.91 36.24 40.58 64.56 67.99 91.57 3.47 3.42 7.12 7.28 8.81 11.66 2.85 7.57 1.48 14.81-6.35 19.33-7.25 4.18-15.76 1.89-21.27-5.34-32.96-38.62-63.38-99.87-63.81-155.13Z"
        data-name="Path 167"
        transform="translate(78.83 127.95)"
      />
      <path
        id="Path_168"
        fill="#fff"
        d="M194.97 162.58c-5.03-13.05-9.56-25.63-14.68-37.97-14.92-36.23-40.58-64.55-68-91.57-3.55-3.42-7.11-7.28-8.9-11.66-2.84-7.56-1.47-14.8 6.36-19.33C117-2.13 125.5.17 131.11 7.4c33.02 38.68 63.42 99.92 63.86 155.19Z"
        data-name="Path 168"
        transform="translate(79.57 .03)"
      />
      <path
        id="Path_169"
        fill="#fff"
        d="M143.82 165.03c10.68.9 20.82 1.96 30.95 2.56 29.92 1.78 57.94-6.44 85.65-16.32a27.14 27.14 0 0 1 10.96-2.17c6.14.55 10.68 3.9 11.2 10.8.47 6.37-3.95 11.46-10.8 12.84-37.53 9.84-89.78 10.4-127.96-7.7Z"
        data-name="Path 169"
        transform="translate(112.16 116.33)"
      />
      <path
        id="Path_170"
        fill="#fff"
        d="M172.32 103.64c-10.68-.91-20.82-1.96-30.95-2.56-29.92-1.78-57.93 6.44-85.65 16.3a27.14 27.14 0 0 1-10.96 2.17c-6.14-.56-10.68-3.9-11.2-10.8-.46-6.38 3.95-11.47 10.8-12.84 37.53-9.82 89.78-10.37 127.96 7.73Z"
        data-name="Path 170"
        transform="translate(26.15 69.56)"
      />
      <path
        id="Path_171"
        fill="#fff"
        d="M103.64 143.82c-.91 10.68-1.96 20.82-2.57 30.95-1.78 29.92 6.45 57.94 16.32 85.65a27.14 27.14 0 0 1 2.17 10.96c-.55 6.14-3.9 10.68-10.8 11.2-6.37.47-11.46-3.95-12.83-10.8-9.84-37.53-10.4-89.78 7.7-127.96Z"
        data-name="Path 171"
        transform="translate(69.5 112.21)"
      />
      <path
        id="Path_172"
        fill="#fff"
        d="M165.03 172.32c.9-10.68 1.96-20.82 2.56-30.95 1.78-29.92-6.44-57.93-16.32-85.65a27.14 27.14 0 0 1-2.17-10.96c.55-6.14 3.9-10.68 10.8-11.2 6.37-.46 11.46 3.95 12.83 10.8 9.85 37.53 10.4 89.78-7.7 127.96Z"
        data-name="Path 172"
        transform="translate(116.27 26.2)"
      />
      <path
        id="Path_173"
        fill="#fff"
        d="M162.06 183.24c6.12-8.9 12.1-17.05 17.7-25.54 16.5-24.92 23.38-53.4 28.68-82.34A27.21 27.21 0 0 1 212 64.8c3.56-5.04 8.72-7.3 14.95-4.29 5.75 2.8 7.94 9.15 5.71 15.79-10.2 37.4-35.84 82.92-70.6 106.95Z"
        data-name="Path 173"
        transform="translate(126.38 46.18)"
      />
      <path
        id="Path_174"
        fill="#fff"
        d="M124.58 126.46c-6.12 8.9-12.1 17.05-17.7 25.54-16.49 25-23.38 53.4-28.68 82.34a26.93 26.93 0 0 1-3.56 10.57c-3.56 5.04-8.72 7.3-14.95 4.29-5.75-2.8-7.94-9.15-5.71-15.79 10.2-37.4 35.84-82.85 70.6-106.95Z"
        data-name="Path 174"
        transform="translate(41.42 98.67)"
      />
      <path
        id="Path_175"
        fill="#fff"
        d="M126.46 162.06c8.9 6.12 17.05 12.1 25.54 17.7 25 16.5 53.4 23.38 82.34 28.68A27.21 27.21 0 0 1 244.9 212c5.04 3.56 7.3 8.72 4.29 14.95-2.8 5.75-9.15 7.94-15.79 5.71-37.4-10.2-82.85-35.84-106.95-70.6Z"
        data-name="Path 175"
        transform="translate(98.62 126.43)"
      />
      <path
        id="Path_176"
        fill="#fff"
        d="M183.24 124.58c-8.9-6.12-17.05-12.1-25.54-17.7C132.78 90.4 104.3 83.5 75.37 78.2a26.93 26.93 0 0 1-10.58-3.56c-5.03-3.56-7.3-8.72-4.29-14.95 2.8-5.75 9.15-7.94 15.8-5.71 37.39 10.2 82.91 35.84 106.94 70.6Z"
        data-name="Path 176"
        transform="translate(46.13 41.48)"
      />
      <path
        id="Path_177"
        fill="#fff"
        d="M156.85 180.53c9.72-4.57 19-8.72 28.09-13.26 26.8-13.4 46.95-34.55 66.01-56.95a27.07 27.07 0 0 1 8.4-7.37c5.6-2.58 11.21-1.96 15.1 3.77 3.55 5.34 2.3 11.89-2.94 16.52-27.57 27.21-72.55 53.9-114.66 57.29Z"
        data-name="Path 177"
        transform="translate(122.32 79.2)"
      />
      <path
        id="Path_178"
        fill="#fff"
        d="M150.89 109.43c-9.75 4.57-19 8.72-28.09 13.26-26.8 13.4-46.95 34.55-66.01 56.96a27.07 27.07 0 0 1-8.4 7.33c-5.6 2.58-11.21 1.96-15.1-3.77-3.55-5.34-2.3-11.9 2.94-16.52 27.57-27.23 72.55-53.84 114.66-57.26Z"
        data-name="Path 178"
        transform="translate(24.39 85.4)"
      />
      <path
        id="Path_179"
        fill="#fff"
        d="M109.43 156.85c4.57 9.72 8.72 19 13.26 28.09 13.4 26.8 34.55 46.95 56.96 66.01a27.07 27.07 0 0 1 7.36 8.4c2.59 5.6 1.96 11.21-3.77 15.1-5.34 3.55-11.89 2.3-16.52-2.94-27.26-27.57-53.87-72.55-57.29-114.66Z"
        data-name="Path 179"
        transform="translate(85.34 122.37)"
      />
      <path
        id="Path_180"
        fill="#fff"
        d="M180.53 150.89c-4.57-9.75-8.72-19-13.26-28.09-13.4-26.8-34.55-46.95-56.95-66.01a27.07 27.07 0 0 1-7.37-8.4c-2.58-5.6-1.96-11.21 3.77-15.1 5.34-3.55 11.89-2.3 16.52 2.94 27.21 27.57 53.9 72.55 57.29 114.66Z"
        data-name="Path 180"
        transform="translate(79.15 24.44)"
      />
    </svg>
  );
};

export default IconSwirlMedium;
