import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import Container from '../components/Layout/SharedStyles/Container';
import ContactForm from '../components/Forms/ContactForm';
import ContactFormGB from '../components/Forms/ContactFormGB';
import ContactFormEU from '../components/Forms/ContactFormEU';
import { PageIntro } from '../components/Layout/PageIntro/PageIntro';

const ContactPages = ({
  data: {
    datoCmsContactPage: { title, id, metaTags },
    homeCrumb,
  },
  pageContext,
}) => {
  const breadcrumbs = [
    { title: homeCrumb.title, id: homeCrumb.id },
    { title: title, id: id },
  ];

  const renderLocaleForm = () => {
    switch (pageContext.locale) {
        case "en-GB":
            return <ContactFormGB locale={pageContext.locale} />;
        case "en-EU":
            return <ContactFormEU locale={pageContext.locale} />;            
        default:
            return <ContactForm locale={pageContext.locale} />;
    }
};  

  return (
    <PageWrapper pageData={pageContext} metaTags={metaTags}>
      <PageIntro title={title} breadcrumbs={breadcrumbs} />
      <Container width="medium" pt={0} pb={2}>
        {renderLocaleForm()}
      </Container>
    </PageWrapper>
  );
};

export default ContactPages;

export const query = graphql`
  query ContactPageQuery($locale: String!) {
    datoCmsContactPage(locale: { eq: $locale }) {
      locale
      title
      metaTags {
        title
        description
        image {
          url
        }
      }
      id: originalId
    }
    homeCrumb: datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      id: originalId
    }
  }
`;
