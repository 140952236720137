import React from 'react';
import style from './formRow.mod.scss';

const FormRow = ({ children, columns = 0, gaps = 0 }) => {
  const classes = [style.formRow];
  classes.push(`${style.formRow}--column-${columns}`);
  classes.push(`${style.formRow}--gap-${gaps}`);

  return <div className={[...classes].join(' ')}>{children}</div>;
};

export default FormRow;
