import React, { useState, useRef, useCallback } from 'react';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import style from './formSelect.mod.scss';

const FormSelect = ({ label, name, placeholder, value, list }) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const error = errors.hasOwnProperty(name) ? errors[name] : null;

  const classes = cn(style.formSelect__selectWrapper, {
    [style[`formSelect__selectWrapper-error`]]: !!error === true,
  });

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(value ? value : '');

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <div className={style.formSelect}>
      {label && (
        <label className={style.formSelect__label} htmlFor={label}>
          {label}
        </label>
      )}
      <div className={style.formSelect__content}>
        <div>
          <span
            className={classes}
            ref={anchorRef}
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={() => setOpen(true)}
          >
            {placeholder && selected === '' ? (
              <label className={style.formSelect__placeholder}>
                {placeholder}
              </label>
            ) : (
              <label>{selected}</label>
            )}

            {open ? (
              <KeyboardArrowUpIcon color="primary" />
            ) : (
              <KeyboardArrowDownIcon color="primary" />
            )}
          </span>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            style={{ zIndex: 10 }}
            disablePortal
            placement="bottom-start"
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center top',
                }}
              >
                <Paper style={{ width: '100%' }}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {list.map((listItem) => (
                        <MenuItem
                          style={{ minWidth: '250px', maxWidth: '500px' }}
                          key={listItem}
                          onClick={() => {
                            setSelected(listItem);
                            setValue(`${name}`, listItem, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                            setOpen(false);
                          }}
                        >
                          {listItem}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>

        {!!error && (
          <span className={style.formSelect__error}>{error.message}</span>
        )}
      </div>
    </div>
  );
};

export default FormSelect;
