import React from 'react';
import cn from 'classnames';
import { useFormContext, Controller } from 'react-hook-form';
import style from './formTextArea.mod.scss';

const FormTextArea = ({ rows = 0, label, name, placeholder, defaultValue }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = errors.hasOwnProperty(name) ? errors[name] : null;

  const classes = cn(style.formTextArea__input, {
    [style[`formTextArea__input-error`]]: !!error === true,
  });

  return (
    <div className={style.formTextArea}>
      {label && (
        <label className={style.formTextArea__label} htmlFor={label}>
          {label}
        </label>
      )}

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, name, ref } }) => (
          <div className={style.formTextArea__content}>
            <textarea
              className={classes}
              rows={rows}
              id={label}
              inputRef={ref}
              name={name}
              defaultValue={defaultValue}
              placeholder={placeholder}
              onBlur={onBlur}
              onChange={onChange}
            ></textarea>
            {!!error && (
              <span className={style.formTextArea__error}>
                {error?.message}
              </span>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default FormTextArea;
